import { h } from "vue";
import { ElMessage } from "element-plus";

import { TOAST_COLOR } from "@/constants/toast";

export const showToast = (config) =>
  ElMessage({
    message: h(
      "p",
      null,
      h("span", { style: `color: ${TOAST_COLOR[config.type]}` }, config.message)
    ),
    type: config.type,
    duration: config?.duration || 4000,
  });
