import axios from "axios";

import store from "@/store";

import { showToast } from "@/utils/toast";

// 기본 baseURL을 설정합니다.
const defaultBaseURL = process.env.VUE_APP_BASE_API_URL;

// 인터셉터를 생성할 함수를 정의합니다.
function createAPI(baseURL) {
  return axios.create({
    baseURL: baseURL,
  });
}

// Request interceptor to add the token to each request
const api = createAPI(defaultBaseURL);

function isNeedPrintOut( config ){
  return ( 
    config.url.includes('/printouts') && config.method === 'get'
  )
}

// Request interceptor to add the token to each request
api.interceptors.request.use((config) => {
  const access = localStorage.getItem("access_token");
  if (
    access &&
    !config.url.includes("login") &&
    !config.url.includes("refresh")
  ) {
    config.headers.Authorization = `Bearer ${access}`;
  }

  if (isNeedPrintOut(config)) {
    // config['Accept'] = 'text/csv';
    config.headers['Accept'] = '*/*';
    // config.headers['Content-Type'] = 'application/octet-stream';
    // config.headers['Content-Type'] = 'text/csv; charset=utf-8';
    // config.headers['Content-Disposition'] = `attachment; filename="${fileName}";`
    // config.headers['Pragma'] = `no-cache`;
    // config.headers['Expires'] = 0;
  }

  return config;
});

// Response interceptor to refresh token if needed
// 디바운스 함수 정의
function debounce(func, delay) {
  let timer;
  return function() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

// 디바운스 함수를 이용하여 에러 핸들링 로직 적용
const debouncedErrorHandling = debounce((error) => {
  console.log(`error : `);
  console.log(error);
  if (
    error.response?.status === 401 &&
    !window.location.href.includes("login")
  ) {
    alert(`로그인 인증이 만료되어 로그아웃됩니다`);
    window.localStorage.clear();
    window.location.assign("/login");
  } else if( error.response?.status === 401 && error.response.data.message.includes('LOGOUT')){
    // 로그아웃시 나올 수 있는 권한 없음에 대해서는 alert 처리를 하지 않습니다.
    return;
  } else if ( error.response?.status === 400 && error.response.data.message.includes('ALREADY_SETTLED')) {
    alert(`예식 정산에 실패하였습니다. (이미 정산이 되었습니다.)`);
  } else if (error.response?.status === 403) {
    alert(`실행할 수 있는 권한이 없습니다.`);
  } else if (error.response?.status === 409) {
    alert(`이미 존재하는 데이터에 대한 접근입니다.`);
  } else if (error.response?.status === 500) {
    alert(`알 수 없는 오류가 발생하였습니다`);
  } else if (error.response?.status >= 501) {
    alert(`서버 연결에 실패하였습니다.`);
  } else {
    alert('잠시만 기다려주세요');
  }
}, 1000); // 1초 디바운스 설정

// 레스폰스 헤더
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    debouncedErrorHandling(error);
    return error;
  }
);

export default api;
