import { createApp } from "vue";
import App from "./App.vue";

import print from 'vue3-print-nb'

import "reset-css";
import "../public/pretendard.css";
import "@/assets/scss/global.scss";

import router from "./router";
import store from "./store";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import ko from "element-plus/dist/locale/ko";



import dayjs from "dayjs";
dayjs.Ls.en.weekStart = 1;
dayjs.Ls.de ??= {};
dayjs.Ls.de.weekStart = 1;

import VueApexCharts from "vue3-apexcharts";

import vClickOutside from "click-outside-vue3";

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: ko,
  })
  .use(vClickOutside)
  .use(print)
  
  .component("apexchart", VueApexCharts)
  .mount("#app");
