import { getAdminId } from "@/utils/id";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/layout/MasterAdminLayout.vue"),
    children: [
      {
        path: "",
        name: "MasterView",
        component: () => import("@/views/BlankView.vue"),
        beforeEnter: (to, from, next) => {
          next({ name: "HallClickManagementView" });
        },
      },
      // 업체 관리
      {
        path: "hallclick",
        name: "HallClickManagementLayout",
        component: () =>
          import(
            "@/views/HallClickManagement/HallClickManagementLayout.vue"
          ),
        children: [
          {
            path: "",
            name: "HallClickManagementView",
            beforeEnter: (to, from, next) => {
              next({ name: "CompanyManagementView" });
            },
          },
          // 업체 관리
          {
            path: "company",
            name: "CompanyManagementView",
            component: () =>
              import(
                "@/views/HallClickManagement/company/CompanyManagementView.vue"
              ),
            children: [

            ]
          },
          // 업체 상세
          {
            path: "company/:id",
            name: "CompanyDetailView",
            meta: {
              parent: "CompanyManagementView",
            },
            component: () =>
              import(
                "@/views/HallClickManagement/company/CompanyDetailView.vue"
              ),
          },
          // 정산 관리
          {
            path: "settlement",
            name: "SettlementManagementView",
            component: () =>
              import(
                "@/views/HallClickManagement/settlement/SettleManagementView.vue"
              ),
          },
        ],
      },
      {
        path: "wedding-click",
        component: () =>
          import(
            "@/views/WeddingClickManagement/WeddingClickManagementLayout.vue"
          ),
        children: [
            // 작성 내용 관리
            {
              path: "main/:state?",
              name: "WhatToWriteManagementView",
              component: () =>
                import(
                  "@/views/WeddingClickManagement/what-to-write/WhatToWriteManagementView.vue"
                ),
              props: route => ({
                state: route.params.state || 'defaultState'
              })
            },
            // 작성 내용 관리 > 이벤트 > 이벤트 등록
            {
              path: "main/event/register",
              name: "EventRegister",
              meta: {
                parent: "WhatToWriteManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/what-to-write/EventDetail.vue"
                ),
            },
             // 작성 내용 관리 > 이벤트 > 이벤트 상세
             {
              path: "main/event/:id",
              name: "EventDetail",
              meta: {
                parent: "WhatToWriteManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/what-to-write/EventDetail.vue"
                ),
            },
            // 작성 내용 관리 > 웨딩 정복 > 웨딩 정복 등록
            {
              path: "main/weddingInfo/register",
              name: "WeddingInfoRegister",
              meta: {
                parent: "WhatToWriteManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/what-to-write/WeddingInfoDetail.vue"
                ),
            },
            // 작성 내용 관리 > 웨딩 정복 > 웨딩 정복 상세
            {
              path: "main/weddingInfo/:id",
              name: "WeddingInfoDetail",
              meta: {
                parent: "WhatToWriteManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/what-to-write/WeddingInfoDetail.vue"
                ),
            },
            // 작성 내용 관리 > 공지사항 > 공지사항 등록
            {
              path: "main/notice/register",
              name: "NoticeRegister",
              meta: {
                parent: "WhatToWriteManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/what-to-write/NoticeDetail.vue"
                ),
            },
            // 작성 내용 관리 > 공지사항 > 공지사항 상세
            {
              path: "main/notice/:id",
              name: "NoticeDetail",
              meta: {
                parent: "WhatToWriteManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/what-to-write/NoticeDetail.vue"
                ),
            },
            // 유저 관리
            {
              path: "member",
              name: "MemberManagementView",
              component: () =>
                import(
                  "@/views/WeddingClickManagement/member/MemberManagementView.vue"
                ),
            },
            // 유저 관리 > 고객 > 고객 상세
            {
              path: "member/customer/:id",
              name: "CustomerDetail",
              meta: {
                parent: "MemberManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/member/CustomerDetail.vue"
                ),
            },
            // 유저 관리 > 플래너 > 플래너 상세
            {
              path: "member/planner/:id",
              name: "PlannerDetail",
              meta: {
                parent: "MemberManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/member/PlannerDetail.vue"
                ),
            },
            // 커뮤니티 관리
            {
              path: "community",
              name: "CommunityManagementView",
              component: () =>
                import(
                  "@/views/WeddingClickManagement/community/CommunityManagementView.vue"
                ),
            },
            // 커뮤니티 관리 > 고객 > 고객 상세 || 플래너 상세
            {
              path: "community/customer/:id",
              name: "CommunityCustomerDetail",
              meta: {
                parent: "CommunityManagementView",
              },
              component: () =>
                import(
                  "@/views/WeddingClickManagement/community/CommunityCustomerDetail.vue"
                ),
            },
        ],
      },
      {
        path: "master",
        name: "MasterAdminBase",
        component: () =>
          import(
            "@/views/MasterAdmin/MasterAdminBase.vue"
          ),
      },
      {
        path: "master/register",
        name: "MasterAdminRegister",
        component: () =>
          import(
            "@/views/MasterAdmin/MasterAdminDetail.vue"
          ),
      },
      {
        path: "master/:id",
        name: "MasterAdminDetail",
        component: () =>
          import(
            "@/views/MasterAdmin/MasterAdminDetail.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "MasterLoginPage",
    component: () => import("@/views/MasterLoginView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * 로그인한 상태가 아니라면 어떤 경로로 들어가던지 간에 로그인 페이지로 리디렉션
 */
router.beforeEach(async (to, from) => {
  const isAuthenticated = localStorage.getItem("access_token");
  if(
    !isAuthenticated &&
    to.name !== 'MasterLoginPage'
  ) {
    alert(`로그인 인증이 만료되어 로그아웃됩니다`);
    window.localStorage.clear();
    return { name : 'MasterLoginPage' }
  }
})

router.addRoute({
  path: '/:pathMatch(.*)*',
  component: () => import("@/views/HallClickManagement/company/CompanyManagementView.vue")
})

export default router;
