import { login, logout, refreshToken } from "@/services/auth";

import { showToast } from "@/utils/toast";

export default {
  namespaced: true,
  state() {
    return {
      profile: {},
    };
  },
  getters: {
    getProfile: (state) => state.profile,
  },
  mutations: {
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
  },
  actions: {
    async loginMth({ commit }, payload) {
      const res = await login(payload);
      if (res?.status >= 200 && res?.status < 399) {
        localStorage.setItem(
          "access_token",
          res?.data?.jwt,
        );
        localStorage.setItem(
          "adminId",
          res?.data?.adminId,
        );
        localStorage.setItem("user_info", JSON.stringify({
          username : res?.data?.username,
          name : res?.data?.name,
        }));
        localStorage.setItem(
          "refresh_token",
          res?.data?.refresh_token,
        );
        showToast({
          type: "success",
          message: "성공적으로 로그인되었습니다.",
        });
        commit("SET_PROFILE", res?.data?.data?.hall_staff);
      } else {
        showToast({
          type: "error",
          message: "로그인 정보가 올바르지 않습니다.",
        });
      }
      return res;
    },
    async refresh() {
      const res = await refreshToken();
      if (res?.data?.status === "ok") {
        localStorage.setItem(
          "access_token",
          res.data?.data?.token?.access_token
        );
        localStorage.setItem(
          "refresh_token",
          res.data?.data?.token?.refresh_token
        );
      }
      return res;
    },
    async logout({ commit }) {
      const loginedUser = {
        username : JSON.parse(window.localStorage.getItem("user_info"))?.username,
        jwt : localStorage.getItem('access_token'),
      }
      logout(loginedUser);

      commit("SET_PROFILE", {});
    },
    // async getProfileMth({ commit }) {
    //   const res = await api.get(`auth/me`);
    //   commit("SET_PROFILE", res.data);
    // },
  },
};
