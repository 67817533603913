import api from "@/boot/api";

export const login = async (payload) => {
  return await api("postAuthTokens", payload);
};

export const logout = async (payload) => {
  return await api("deleteAuthTokens", payload);
};

export const refreshToken = async () => {
  const refresh_token = localStorage.getItem("refresh_token");
  return api.get("auth/refresh", {
    headers: { Authorization: `Bearer ${refresh_token}` },
  });
};
