import { getAdminId } from '@/utils/id';
import axios from './axios'
import FormData from 'form-data';

const configApi = {
    // default api
    getHealth: () => ({
        method: 'get',
        url: '/health'
    }),

    // auth
    /**
     * 관리자 로그인
     * @param {*} payload 
     * @returns 
     */
    postAuthTokens: ( payload ) => ({
        method: 'post',
        url: '/auth/tokens',
        payload
    }),
    /**
     * 관리자 로그아웃
     * @param {*} payload 
     * @returns 
     */
    deleteAuthTokens: ( payload ) => ({
        method: 'delete',
        url: '/auth/tokens',
        payload
    }),

    /**
     * 비밀번호 변경
     * @param {*} payload 
     * @returns 
     */
    getChangeAccountsPassword: ( payload ) => ({
        method: 'patch',
        url: '/auth/password',
        payload
    }),

    // admin api
    /**
     * 관리자 계정 생성
     * @returns 
     */
    postAdmin: (payload) => ({
        method: 'post',
        url: '/admin',
        payload
    }),
    /**
     * 관리자 계정 목록 조회
     * @returns 
     */
    getAdminList: (payload) => ({
        method: 'get',
        url: `/admin?page=${payload.page||1}&limit=${payload.limit||10}${payload.startTime?`&startTime=${payload.startTime}`:''}${payload.endTime?`&endTime=${payload.endTime}`:''}${payload.name?`&name=${payload.name}`:''}`,
    }),
    /**
     * 관리자 계정 다중삭제
     * @returns 
     */
    deleteAdmin: (payload) => ({
        method: 'delete',
        url: '/admin',
        payload
    }),
    /**
     * 관리자 단일 조회
     * @returns 
     */
    getAdminDetail: (payload) => ({
        method: 'get',
        url: `/admin/${payload.id || ''}`,
    }),
    /**
     * 관리자 계정 정보 수정
     * @returns 
     */
    patchAdmin: (payload) => ({
        method: 'patch',
        url: `/admin/${payload.id || ''}${payload.body}`,
    }),

    // inquiry api
    /**
     * 랜딩페이지 제휴 문의 조회, 대기 리스트 가져오기
     * @param {*} payload 
     * @returns 
     */
    getLandingInquiriesList: ( payload ) => {
        let url = `/inquiries?page=${payload.page||''}&limit=${payload.limit||''}`;
        url+=`${payload.startDate?`&startDate=${payload.startDate||''}`:''}`;
        url+=`${payload.endDate?`&endDate=${payload.endDate||''}`:''}`;
        url+=`${payload.weddingHallName?`&weddingHallName=${payload.weddingHallName||''}`:''}`;
        url+=`${payload.filter?`&filter=${payload.filter||''}`:''}`;
        url+=`${payload.order?`&order=${payload.order||''}`:''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 제휴 요청 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteInquiries: ( payload ) => ({
        method: 'delete',
        url: '/inquiries',
        payload
    }),

    /**
     * 제휴 요청 단일 조회
     * @param {*} payload 
     * @returns 
     */
    getLandingInquiriesDetail: ( payload ) => {
        let url = `/inquiries/${payload.id || 1}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 상세 페이지 조회, 수정을 위한 단일 페이지 정보 가져오기
     * @param {*} payload 
     * @returns 
     */
    getDetailCompany: ( payload ) => ({
        method: 'get',
        url: `/inquiries/${payload.id || 1}`,
    }),
    /**
     * 승인하기
     */
    getConfirmLandingCompany: ( payload ) => ({
        method: 'patch',
        url: `/inquiries/${payload.id || 1}/approval`,
    }),

    // weddingHalls api
    /**
     * 승인된 업체 조회, 필터, 페이지네이션
     */
    getConfirmedWeddingHallList: ( payload ) => {
        let url = `/wedding-halls?page=${payload.page||''}&limit=${payload.limit||''}`;
        url+=`${payload.startDate?`&startDate=${payload.startDate||''}`:''}`;
        url+=`${payload.endDate?`&endDate=${payload.endDate||''}`:''}`;
        url+=`${payload.weddingHallName?`&weddingHallName=${payload.weddingHallName||''}`:''}`;
        url+=`${payload.filter?`&filter=${payload.filter||''}`:''}`;
        url+=`${payload.order?`&order=${payload.order||''}`:''}`;
        url+=`&state=${payload.state||'사용'}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 삭제처리된 업체 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteWeddingHalls: ( payload ) => ({
        method: 'delete',
        url: '/wedding-halls',
        payload
    }),
    /**
     * 승인 업체 신규 등록
     * @param {*} payload 
     * @returns 
     */
    postWeddingHalls: ( payload ) => ({
        method: 'post',
        url: '/wedding-halls',
        payload
    }),

    /**
     * 승인된 업체 단일 조회
     * @param {*} payload 
     * @returns 
     */
    getConfirmedWeddingHallDetail: ( payload ) => {
        let url = `/wedding-halls/${payload.id || 1}`;
        return {
            method: 'get',
            url,
        }
    },

    /**
     * 승인된 업체 단일 조회 - 예식 정산 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getConfirmedWeddingHallsSettlements: ( payload ) => ({
        method: 'get',
        url: `/wedding-halls/${payload?.id || 1}/settlements${payload?.query || ''}`,
    }),
    /**
     * 승인된 업체 단일 조회 - 업체 정산 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteConfirmedWeddingHallsSettlements: ( payload ) => ({
        method: 'delete',
        url: `/wedding-halls/${payload?.id || 1}/settlements`,
        payload: payload.body,
    }),
    /**
     * 승인된 업체 단일 조회 - 업체 정산 다중 정산
     * @param {*} payload 
     * @returns 
     */
    putConfirmedWeddingHallsSettlements: ( payload ) => ({
        method: 'put',
        url: `/wedding-halls/${payload?.id || 1}/settlements`,
        payload
    }),
    /**
     * 승인된 업체 단일 조회 - 업체 정산 Stat 조회
     * @param {*} payload 
     * @returns 
     */
    getConfirmedWeddingHallsSettlementsStats: ( payload ) => ({
        method: 'get',
        url: `/wedding-halls/${payload?.id || ''}/settlements/stats${payload?.query||''}`,
    }),

    /**
     * 사용 업체 메인 설정 조회 (앱 정보 설정)
     * @param {*} payload 
     * @returns 
     */
    getWeddingHallsMainSetting: ( payload ) => ({
        method: 'get',
        url: `/wedding-halls/${payload.id || 1}/main`,
    }),
    /**
     * 사용 업체 메인 설정 변경 (앱 정보 설정)
     * @param {*} payload 
     * @returns 
     */
    patchWeddingHallsMainSetting: ( payload ) => ({
        method: 'patch',
        url: `/wedding-halls/${payload.id || 1}/main`,
        payload : payload.body,
    }),
    /**
     * 사용 중인 업체 다중 삭제처리
     * @param {*} payload 
     * @returns 
     */
    deleteWeddingHallsRegistration: ( payload ) => ({
        method: 'delete',
        url: `/wedding-halls/registration`,
        payload: payload
    }),
    /**
     * 업체 페이지 접속하기
     * @param {*} payload 
     * @returns 
     */
    postWeddingHallsMasterAccount: ( payload ) => ({
        method: 'post',
        url: `/wedding-halls/${payload?.weddingHallId || ''}/master-account`,
        payload: payload.body,
    }),

    // settlements api
    /**
     * 업체 정산 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getSettlementsHalls: ( payload ) => ({
        method: 'get',
        url: `/settlements/halls${payload||''}`,
    }),
    /**
     * 업체 정산 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteSettlementsHalls: ( payload ) => ({
        method: 'delete',
        url: `/settlements/halls`,
        payload
    }),
    /**
     * 업체 정산 다중 정산
     * @param {*} payload 
     * @returns 
     */
    putSettlementsHalls: ( payload ) => ({
        method: 'put',
        url: `/settlements/halls`,
        payload
    }),
    /**
     * 업체 정산 Stat 조회
     * @param {*} payload 
     * @returns 
     */
    getSettlementsHallsStats: ( payload ) => ({
        method: 'get',
        url: `/settlements/halls/stats${payload||''}`,
    }),
    /**
     * 플래너 정산 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getSettlementsPlanners: ( payload ) => ({
        method: 'get',
        url: `/settlements/planners${payload||''}`,
    }),
    /**
     * 플래너 정산 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteSettlementsPlanners: ( payload ) => ({
        method: 'delete',
        url: `/settlements/planners`,
        payload
    }),
    /**
     * 플래너 정산 다중 정산
     * @param {*} payload 
     * @returns 
     */
    putSettlementsPlanners: ( payload ) => ({
        method: 'put',
        url: `/settlements/planners`,
        payload
    }),

    // events api
    /**
     * 이벤트 등록하기
     */
    postEvents: ( payload ) => ({
        method: 'post',
        url: `/events`,
        payload: payload.body,
    }),
    /**
     * 이벤트 목록 조회
     */
    getEventsList: ( payload ) => {
        let url = `/events?page=${payload.page||''}&limit=${payload.limit||''}`;
        url+=`${payload.startTime?`&startTime=${payload.startTime}`:''}${payload.endTime?`&endTime=${payload.endTime}`:''}${payload?.title ? `&title=${payload.title}`:''}`;
        url+=`${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}`;
        return {
            method: 'get',
            url,
        }
    },
    /**
     * 이벤트 다중 삭제
     */
    deleteEvents: ( payload ) => ({
        method: 'delete',
        url: `/events`,
        payload
    }),
    /**
     * 이벤트 단일 조회
     */
    getEventsDetail: ( payload ) => ({
        method: 'get',
        url: `/events/${payload.id || ''}`,
    }),
    /**
     * 이벤트 수정
     */
    patchEvents: ( payload ) => ({
        method: 'patch',
        url: `/events/${payload.id || ''}`,
        payload: payload?.body
    }),

    // wedding-conquer, 웨딩 정복
    /**
     * 웨딩 정복 등록하기
     * @param {*} payload 
     * @returns 
     */
    postWeddingConquer: ( payload ) => ({
        method: 'post',
        url: `/wedding-conquer`,
        payload: payload.body
    }),
    /**
     * 웨딩 정복 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getWeddingConquerList: ( payload ) => ({
        method: 'get',
        url: `/wedding-conquer?page=${payload.page||''}&limit=${payload.limit||''}${payload.startTime?`&startTime=${payload.startTime}`:''}${payload.endTime?`&endTime=${payload.endTime}`:''}${payload.title?`&title=${payload.title}`:''}${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}`,
    }),
    /**
     * 웨딩 정복 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteWeddingConquer: ( payload ) => ({
        method: 'delete',
        url: `/wedding-conquer`,
        payload
    }),
    /**
     * 웨딩 정복 단일 조회
     * @param {*} payload 
     * @returns 
     */
    getWeddingConquerDetail: ( payload ) => ({
        method: 'get',
        url: `/wedding-conquer/${payload.id || ''}`,
    }),
    /**
     * 웨딩 정복 수정
     * @param {*} payload 
     * @returns 
     */
    patchWeddingConquer: ( payload ) => ({
        method: 'patch',
        url: `/wedding-conquer/${payload.id || ''}`,
        payload: payload.body,
    }),

    // community
    /**
     * 커뮤니티 게시글 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getCommunityPosts: ( payload ) => ({
        method: 'get',
        url: `/community/posts?page=${payload.page||1}&limit=${payload.limit||10}${payload?.isClientOnly?`&isClientOnly=${payload.isClientOnly||true}`:''}${payload.startDate?`&startDate=${payload.startDate}`:''}${payload.endDate?`&endDate=${payload.endDate}`:''}${payload.title?`&title=${payload.title}`:''}${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}`,
    }),
    /**
     * 커뮤니티 게시글 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteCommunityPosts: ( payload ) => ({
        method: 'delete',
        url: `/community/posts`,
        payload
    }),
    /**
     * 커뮤니티 게시글 상세정보 조회
     * @param {*} payload 
     * @returns 
     */
    getCommunityPostsDetail: ( payload ) => ({
        method: 'get',
        url: `/community/posts/${payload.id || ''}`,
    }),
    /**
     * 커뮤니티 덧글 상세정보 조회
     * @param {*} payload 
     * @returns 
     */
    getCommunityPostsComments: ( payload ) => ({
        method: 'get',
        url: `/community/posts/${payload?.id || ''}/comments${payload?.query||''}`,
    }),
    /**
     * 커뮤니티 댓글 다중삭제
     * @param {*} payload 
     * @returns 
     */
    deleteCommunityPostsComments: ( payload ) => ({
        method: 'delete',
        url: `/community/posts/${payload.postId || ''}/comments`,
        payload: payload.body,
    }),

    // community-notice api
    /**
     * 커뮤니티 공지사항 등록하기
     * @param {*} payload 
     * @returns 
     */
    postCommunityNotice: ( payload ) => ({
        method: 'post',
        url: `/community-notice`,
        payload: payload,
    }),
    /**
     * 커뮤니티 공지사항 목록 조회
     */
    getCommunityNoticeList: ( payload ) => ({
        method: 'get',
        url: `/community-notice?page=${payload.page||1}&limit=${payload.limit||10}${payload.startTime?`&startTime=${payload.startTime}`:''}${payload.endTime?`&endTime=${payload.endTime}`:''}${payload.title?`&title=${payload.title}`:''}${payload.filter?`&filter=${payload.filter}`:''}${payload.order?`&order=${payload.order}`:''}`,
    }),
    /**
     * 커뮤니티 공지사항 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteCommunityNotice: ( payload ) => ({
        method: 'delete',
        url: `/community-notice`,
        payload
    }),
    /**
     * 커뮤니티 공지사항 단일 조회
     * @param {*} payload 
     * @returns 
     */
    getCommunityNoticeDetail: ( payload ) => ({
        method: 'get',
        url: `/community-notice/${payload.id || ''}`,
    }),
    /**
     * 커뮤니티 공지사항 수정
     * @param {*} payload 
     * @returns 
     */
    patchCommunityNotice: ( payload ) => ({
        method: 'patch',
        url: `/community-notice/${payload.id || ''}`,
        payload: payload.body,
    }),

    // user api
    /**
     * 고객 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getUserList: ( payload ) => ({
        method: 'get',
        url: `/users/clients${payload}`
    }),
    /**
     * 고객 다중 삭제 (탈퇴)
     */
    deleteUsersClients: ( payload ) => ({
        method: 'delete',
        url: `/users/clients`,
        payload
    }),
    /**
     * 고객 상세 조회
     * @param {*} payload 
     * @returns 
     */
    getUsersClientsDetail: ( payload ) => ({
        method: 'get',
        url: `/users/clients/${payload.id || ''}`,
    }),
    /**
     * 고객 비고 저장/수정
     * @param {*} payload 
     * @returns 
     */
    putUsersClientsMemo: ( payload ) => ({
        method: 'put',
        url: `/users/clients/${payload.id || ''}/memo`,
        payload: payload.body,
    }),
    /**
     * 고객 컨택 업체 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getUsersClientsContactedHalls: ( payload ) => ({
        method: 'get',
        url: `/users/clients/${payload.id || ''}/contacted-halls${payload?.query||''}`,
    }),
    /**
     * 플래너 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getUsersPlanners: ( payload ) => ({
        method: 'get',
        url: `/users/planners${payload}`
    }),
    /**
     * 플래너 다중 삭제(탈퇴)
     * @param {*} payload 
     * @returns 
     */
    deleteUsersPlanners: ( payload ) => ({
        method: 'delete',
        url: `/users/planners`,
        payload
    }),

    /**
     * 플래너 승인하기
     */
    putUsersPlannersApproval: ( payload ) =>({
        method: 'put',
        url: `/users/planners/${payload?.id||''}/approval`,
    }),

    /**
     * 플래너 상세 조회
     * @param {*} payload 
     * @returns 
     */
    getUsersPlannersDetail: ( payload ) => ({
        method: 'get',
        url: `/users/planners/${payload.id||''}`,
    }),

    /**
     * 플래너 상세 조회 - 예식 정산 목록 조회
     * @param {*} payload 
     * @returns 
     */
    getUsersPlannersSettlements: ( payload ) => ({
        method: 'get',
        url: `/users/planners/${payload.id||''}/settlements${payload?.query||''}`,
    }),
    /**
     * 플래너 상세 조회 - 예식 정산 다중 삭제
     * @param {*} payload 
     * @returns 
     */
    deleteUsersPlannersSettlements: ( payload ) => ({
        method: 'delete',
        url: `/users/planners/${payload.id||''}/settlements`,
        payload: payload.body
    }),
    /**
     * 예식 정산 다중 정산
     * @param {*} payload 
     * @returns 
     */
    putUsersPlannersSettlements: ( payload ) => ({
        method: 'put',
        url: `/users/planners/${payload.id||''}/settlements`,
        payload: payload.body,
    }),
    /**
     * 플래너 상세 조회 - 예식 정산 Stat 조회
     * @param {*} payload 
     * @returns 
     */
    getUsersPlannersSettlementsStats: ( payload ) => ({
        method: 'get',
        url: `/users/planners/${payload.id||''}/settlements/stats${payload?.query||''}`,
    }),
}

const allowedApiNames = ["postWeddingConquer", "patchWeddingConquer", "patchEvents", "postEvents", "postCommunityNotice", "patchCommunityNotice"]

function isMultipartApi( name ){
    return allowedApiNames.includes(name);
}

async function api(name, payload) {
    const config = configApi[name](payload)
    try {
        let res = null

        const contentType = isMultipartApi(name) ? 'multipart/form-data' : 'application/json; charset=utf-8';

        const headers = {
            'Content-Type': contentType,
        };
        
        if (config?.payload == undefined) {
            res = await axios[config.method](config.url, headers)
        }
        else {
            res = await axios[config.method](
                config.url,
                config.method != 'delete' ? config?.payload : { data: config?.payload },
                { headers }
            );
        }
        return res
    } catch (error) {
        return error;
    }
}

export async function getCsvFile( name, payload ){
    const config = configApi[name](payload)
    try {
        let res = null

        const headers = {
            // "content-disposition": 'attachment',
            // "Content-Type": "text/csv; charset=utf-8",
            // "Content-Type": "application/octet-stream",
        };
        
        if (config?.payload == undefined) {
            res = await axios[config.method](config.url, headers)
        }
        else {
            res = await axios[config.method](
                config.url,
                config.method != 'delete' ? config?.payload : { data: config?.payload },
                { headers }
            );
        }
        console.log(`getCsvFile : ${JSON.stringify(res)}`);
        return res
    } catch (error) {
        return error;
    }
}

export default api