import { showToast } from "@/utils/toast";
import api from "@/boot/api";

export default {
    namespaced: true,
    state() {
      return {
        selected: [],
        messages: [], // 채팅 메시지 데이터
      };
    },
    getters: {
      // 모든 채팅 메시지 가져오기
      getAllMessages: (state) => state.messages,
    },
    mutations: {
      // 새로운 채팅 메시지 추가
      ADD_MESSAGE(state, message) {
        state.messages.push(message);
      },
      // 채팅 메시지 초기화
      CLEAR_MESSAGES(state) {
        state.messages = [];
      },
      SAVE_CHECKBOX( state, checkbox ){
        state.selected.push(checkbox);
        console.log( "======="+state.selected );
        return '369'
      },
    },
    actions: {
        saveCheckbox({ commit }, payload ){
            state.selected.push( payload );
            console.log( state.selected );
            return
        },
        getCheckbox({ commit }, payload ){
            return state.selected;
        },
    },
  };